import React from "react"

export const BlockLink = ({
  url = "#",
  content = "Sample Content",
  className = "",
  newWindow = false,
}) => {
  return (
    <a
      href={url}
      target={`${newWindow ? "_blank" : "_self"}`}
      rel={`${newWindow ? "noopener noreferrer" : ""}`}
      className={` break-all inlineLink ${className}`}
    >
      {content}
    </a>
  )
}
