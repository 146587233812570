import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"

import { sanityConfig } from "../sanityConfig"

const PortableTextImage = ({ imageAssetId, altText }) => {
  const imageData = getGatsbyImageData(
    imageAssetId,
    { width: 700 },
    sanityConfig
  )
  return <GatsbyImage image={imageData} alt={altText} className="" />
}

export default PortableTextImage
