import React from "react"
import PortableText from "@sanity/block-content-to-react"
import { BlockLink } from "./PortableTextComponents"
import { GatsbyImage } from "gatsby-plugin-image"
import PortableTextImage from "./PortableTextImage"

const PortableTextPrimary = ({ blockData }) => {
  const serializer = {
    marks: {
      link: (props) => (
        <>
          <BlockLink url={props.mark.href} content={props.children} />
        </>
      ),
    },
    types: {
      inlineImage: (props) => {
        const sanityImageAssetId = props.node.asset.id
        const { alt } = props?.node || ""
        return (
          <figure className="flex flex-col items-center justify-center my-10 justify-self-center ">
            <PortableTextImage
              imageAssetId={sanityImageAssetId}
              altText={alt}
            />
            {props.node.caption && (
              <figcaption className="font-bold text-center mt-1 px-5">
                {props.node.caption}
              </figcaption>
            )}
          </figure>
        )
      },
      block: (props) => {
        const { style = "normal" } = props.node
        if (style === "h1") {
          return <h1 className="ptHeader">{props.children}</h1>
        }
        if (style === "h2") {
          return <h2 className="ptHeader">{props.children}</h2>
        }
        if (style === "h3") {
          return <h3 className="ptHeader">{props.children}</h3>
        }
        if (style === "h4") {
          return <h4 className="ptHeader">{props.children}</h4>
        }
        if (style === "normal") {
          return <p className="text-black mb-5 md:mb-8">{props.children}</p>
        }
        return PortableText.defaultSerializers.types.block(props)
      },
    },
    list: (props) =>
      // console.log("list", props) ||
      props.type === "bullet" ? (
        <ul className="mb-10 text-base lg:text-lg leading-7">
          {props.children}
        </ul>
      ) : (
        <ol className="mb-10 text-base lg:text-lg leading-7">
          {props.children}
        </ol>
      ),
  }
  // Fall back to default handling
  return (
    <PortableText
      blocks={blockData}
      serializers={serializer}
      imageOptions={{ w: 320, h: 240, fit: "max" }}
      projectId={process.env.GATSBY_SANITY_ID}
      dataset={process.env.GATSBY_SANITY_DATASET}
    />
  )
}

export default PortableTextPrimary
