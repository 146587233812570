import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Grid from "../components/grid/Grid"
import SEO from "../components/SEO"
import PortableTextPrimary from "../components/portableText/PortableTextPrimary"
import {
  AiOutlineFacebook,
  AiOutlineLinkedin,
  AiOutlineMail,
  AiOutlineTwitter,
} from "react-icons/ai"
import { FiShare2 } from "react-icons/fi"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"

const ShareIcon = ({ isHidden = true, icon = <FiShare2 />, title = "" }) => (
  <div
    className={` hover:text-button-drop-shadow ${
      isHidden ? "hidden" : "inline-block"
    }  `}
    title={`Share to ${title}`}
  >
    {icon}
  </div>
)

const HorizontalRule = () => (
  <hr className=" col-span-full md:col-start-2 md:col-span-6 lg:col-start-4 md:col-end-12 border-t-2 border-brand-light-blue" />
)

const BlogPostTemplate = ({ data, pageContext }) => {
  const [iconsHidden, setIconsHidden] = useState(true)
  const { sanityBlogPost } = data
  const shareImage = sanityBlogPost?.featureImage?.asset?.url || null
  const url = `https://art-and-science-physiotherapy.netlify.app/blog/${sanityBlogPost.slug.current}`
  const title = sanityBlogPost.title
  const { next, previous } = pageContext

  const icons = [
    {
      name: "email",
      component: (
        <EmailShareButton
          subject={`Article: ${sanityBlogPost.title}`}
          body={`Check out this article from Art + Science Physiotherapy & Pilates, '${sanityBlogPost.title}'`}
          children={<AiOutlineMail />}
          url={url}
          separator=": "
        />
      ),
    },
    {
      name: "Facebook",
      component: (
        <FacebookShareButton url={url} children={<AiOutlineFacebook />} />
      ),
    },
    {
      name: "LinkedIn",
      component: (
        <LinkedinShareButton
          url={url}
          children={<AiOutlineLinkedin />}
          title={title}
          summary=""
          source="https://www.artandsciencephysio.com/"
        />
      ),
    },
    {
      name: "Twitter",
      component: (
        <TwitterShareButton
          url={url}
          children={<AiOutlineTwitter />}
          title={title}
          hashtags={["Physiotherapy"]}
        />
      ),
    },
    ,
  ]

  return (
    <Layout>
      <SEO title={sanityBlogPost.title} image={shareImage} />
      <div className="text-brand-grey">
        <Grid>
          {/* Date */}
          <div className="col-span-full md:col-start-2 md:col-span-6 lg:col-start-4 text-lg mb-3">
            {sanityBlogPost._createdAt}
          </div>
          {/* Title */}
          <h1 className="col-span-full md:col-start-2 md:col-span-6 lg:col-start-4 mb-10 md:mb-16">
            {sanityBlogPost.title}
          </h1>
          {sanityBlogPost?.featureImage?.asset ? (
            <div className=" col-span-full lg:col-span-8 lg:col-start-3">
              <GatsbyImage
                image={
                  sanityBlogPost?.featureImage?.asset?.gatsbyImageData || null
                }
                className=" max-w-full rounded-lg"
              />
            </div>
          ) : (
            <HorizontalRule />
          )}
          {/* Main Content */}
          <section className="col-span-full md:col-start-2 lg:col-start-4 md:col-end-12 lg:col-span-6  mt-10 md:mt-16">
            <PortableTextPrimary blockData={sanityBlogPost._rawPostContent} />
            {/* Sharing */}
            <div className="flex flex-row  items-start md:flex-row md:justify-start text-storm-blue">
              <div className="text-2xl grid grid-flow-col justify-start gap-x-3 md:gap-x-4  mr-5">
                <FiShare2
                  className={`${
                    iconsHidden ? "inline-block" : "hidden"
                  } cursor-pointer`}
                  onClick={() => setIconsHidden(false)}
                  title="Share"
                />
                {icons.map((icon, index) => (
                  <ShareIcon
                    isHidden={iconsHidden}
                    icon={icon.component}
                    key={`shareIcons-${index}`}
                    title={icon.name}
                  />
                ))}
              </div>
              <div className="hidden sm:inline">Share this blog post</div>
            </div>
          </section>
          <div className="col-span-full lg:col-span-8 lg:col-start-3 mt-10">
            <hr className="  border-t-2 border-brand-light-blue " />
            <div className="flex justify-between mt-5">
              {/* Children are wrapped in divs for layout/flexbox purposes */}
              <div>
                {previous && (
                  <Link
                    to={`/blog/${previous.slug.current}`}
                    className="flex items-center hover:underline font-normal"
                    title={previous.title}
                  >
                    <RiArrowLeftSLine className="mr-2" /> Previous
                    <span className="hidden sm:inline">&nbsp;blog</span> post
                  </Link>
                )}
              </div>
              {/* Children are wrapped in divs for layout/flexbox purposes */}
              <div>
                {next && (
                  <Link
                    to={`/blog/${next.slug.current}`}
                    title={next.title}
                    className="flex items-center hover:underline font-normal"
                  >
                    Next
                    <span className="hidden sm:inline">
                      &nbsp;blog&nbsp;
                    </span>{" "}
                    post
                    <RiArrowRightSLine className="ml-2" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query sanityBlogPostQuery($slug: String!) {
    sanityBlogPost(slug: { current: { eq: $slug } }) {
      title
      _createdAt(formatString: "dddd, MMMM Do, YYYY")
      _rawPostContent(resolveReferences: { maxDepth: 10 })
      featureImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, width: 1500, aspectRatio: 2)
          url
        }
      }
      slug {
        current
      }
    }
  }
`

export default BlogPostTemplate
